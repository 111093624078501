document.addEventListener("turbolinks:load", function() {
  let currently_playing = null;
  let current_progress = null;
  let waveform_holder = null;
  let state = null;
  let audio = null;
  let duration = null;
  let current_time = null;

  // All song players will be in a div that has the class
  // has-song-players

  let song_player_holders = document.querySelectorAll('.has-song-players');

  // Set up play buttons
  for (let song_player_holder of song_player_holders) {
    song_player_holder.addEventListener('click', function(e) {
      let element = e.target;
      if (element.tagName == 'A' && element.getAttribute('rel') == 'player') {
        let song_player = element.closest('.artist-song, .submission-song');
        if (song_player) {
          play_or_pause(song_player, element.dataset.song);
        }
        e.preventDefault();
        e.stopPropagation();
      }
    });
    song_player_holder.addEventListener('contextmenu', function(e) {
      let element = e.target;
      if (element.tagName == 'A' && element.getAttribute('rel') == 'player') {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    });
  }

  function play_or_pause(song_player, audio_url) {
    if (currently_playing) {
      if (currently_playing == song_player) {
        // This is the currently playing song, so pause
        pause_or_continue_playing(currently_playing);
      } else {
        // stop the currently playing song
        stop_playing(currently_playing);
        // and start playing this
        start_playing(song_player, audio_url);
      }
    } else {
      // Nothing playing, so play this
      start_playing(song_player, audio_url);
    }
  }

  function stop_playing(song_player) {
    if (audio) {
      audio.pause();
      audio.currentTime=0.0;
      audio = null;
    }
    song_player.classList.remove('playing');
    song_player.classList.remove('paused');
    current_progress = song_player.querySelector('.play-progress');
    current_progress.style.width = 0;
    current_time.style.display = 'none';
    currently_playing = null;
    state = null;
  }

  function pause_or_continue_playing(song_player) {
    if (state == 'playing') {
      // Need to pause
      state = 'paused';
      song_player.classList.add('paused');
      audio.pause();
    } else if (state == 'paused') {
      state = 'playing';
      song_player.classList.remove('paused');
      audio.play();
    } else {
      // no idea
    }
  }

  function start_playing(song_player, audio_url) {
    let audio_url_no_cache = audio_url + '?at=' + Date.now();
    console.log(`Start playing ${audio_url_no_cache}`);
    currently_playing = song_player;
    current_progress = song_player.querySelector('.play-progress');
    waveform_holder = song_player.querySelector('.waveform-holder');
    if (!waveform_holder) return;
    current_time = song_player.querySelector('.current.time');
    let img_samples = waveform_holder.querySelector('img.samples');
    current_time.style.display = 'block';
    current_time.innerHTML = '0:00';
    state = 'playing';
    song_player.classList.add('playing');
    audio = new Audio(audio_url_no_cache);
    document.addEventListener("turbolinks:before-visit", function() {
      stop_playing(song_player);
    });
    audio.addEventListener('play', function() {
    });
    audio.addEventListener('playing', function() {
    });
    audio.addEventListener('pause', function() {
    });
    audio.addEventListener('ended', function() {
      stop_playing(song_player);
    });
    audio.addEventListener('durationchange', function() {
      duration = audio.duration;
    });
    audio.addEventListener('timeupdate', function() {
      if (audio && audio.currentTime) {
        let width = 100.0 * audio.currentTime / duration;
        current_progress.style.width = `${width}%`;
        current_time.innerHTML = _time_to_minutes_and_seconds(audio.currentTime);
      }
    });
    audio.addEventListener('canplay', function() {
    });
    audio.addEventListener('canplaythrough', function() {
    });
    audio.addEventListener('stalled', function() {
    });
    audio.addEventListener('waiting', function() {
    });
    // Add scrubbing
    current_progress.addEventListener('click', function(e) {
      let offset = e.offsetX / waveform_holder.offsetWidth;
      audio.currentTime = duration * offset;
    });
    img_samples.addEventListener('click', function(e) {
      let offset = e.offsetX / waveform_holder.offsetWidth;
      audio.currentTime = duration * offset;
    });
    audio.play();
  }

  function _time_to_minutes_and_seconds(time) {
    var minutes = Math.floor(time / 60.0);
    var seconds = ('00' + Math.round(time % 60)).substr(-2);
    return '' + minutes + ':' + seconds;
  }
});
