document.addEventListener("turbolinks:load", function() {
  let listing_details = document.querySelectorAll('.listing-details');

  const csrf_token = document.querySelector('meta[name=csrf-token]').content;

  for (let listing_detail of listing_details) {
    let like = listing_detail.querySelector('.listing-favorite');
    let listing_id = listing_detail.dataset['listingId'];
    if (like) {
      like.addEventListener('click', function(e) {
        let sfl_id = like.dataset['sflId'];
        if (sfl_id) {
          // to unlike, we need to send delete request for this id
          fetch(`/my/subscriber_favorite_listings/${sfl_id}.json`, {
            method: 'delete',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrf_token
            },
            credentials: 'same-origin'
          }).then(function(response) {
            // if the response is fine, we can remove the data
            if (response.status == 204) {
              delete like.dataset['sflId'];
              return true;
            }
          });
        } else {
          // to like, we need to create an sfl
          fetch('/my/subscriber_favorite_listings.json', {
            method: 'post',
            body: JSON.stringify({listing_id: listing_id}),
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrf_token
            },
            credentials: 'same-origin'
          }).then(function(response) {
            if (response.status == 201) {
              return response.json();
            }
          }).then(function(data) {
            // if the response is fine, set the sflId data
            if (data) {
              like.dataset['sflId'] = data['id'];
            }
          });
        }
        e.preventDefault();
      });
    }
  }
});
