$(document).on('turbolinks:load', function() {
  $('table.sortable-locally').each(function() {
    $(this).sortable({
      dropOnEmpty: false,
      cursor: 'move',
      items: 'tr',
      opacity: 0.4,
      scroll: true,
      update: function() {
        $(this).find('input[name*=position]').each(function(idx) {
          $(this).val(idx+1);
        });
      }
    });
  });
});
