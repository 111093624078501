$(document).on('turbolinks:load', function() {
  $('input#different_billing_address').each(function() {
    function setup_billing_address_form() {
      var different_billing_address = $('#different_billing_address').prop('checked');
      var new_billing_address = $('#new_billing_address').prop('checked');
      if (different_billing_address) {
        $('#billing_addresses').show();
        // console.log("show billing addresses");
      } else {
        $('#billing_addresses').hide();
        // console.log("hide billing addresses");
      }
      if (different_billing_address && new_billing_address) {
        $('#different_billing_address').parents('form').find('#billing_addresses input.required').prop('required',true);
        $('#new_billing_address_fields').show();
        // console.log("new billing address fields required");
      } else {
        $('#different_billing_address').parents('form').find('#billing_addresses input.required').prop('required',false);
        $('#new_billing_address_fields').hide();
        // console.log("new billing address fields not required");
      }
    }

    $('input#different_billing_address, input[name=billing_address_choice]').on('change', function() {
      setup_billing_address_form();
    });

    setup_billing_address_form();
  });
});
