document.addEventListener("turbolinks:load", function() {
  let follow_buttons = document.querySelectorAll('.btn-follow');

  for (let follow_button of follow_buttons) {
    let artist_id = follow_button.dataset['artistId'];
    const csrf_token = document.querySelector('meta[name=csrf-token]').content;
    if (artist_id) {
      follow_button.addEventListener('click', function(e) {
        let artist_follow_id = follow_button.dataset['artistFollowId'];
        if (artist_follow_id) {
          // to unfollow, we need to send delete request for this id
          fetch(`/my/follows/${artist_follow_id}.json`, {
            method: 'delete',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrf_token
            },
            credentials: 'same-origin'
          }).then(function(response) {
            // if the response is fine, we can remove the data
            if (response.status == 204) {
              delete follow_button.dataset['artistFollowId'];
              follow_button.innerHTML = 'Follow'
              return true;
            }
          });
        } else {
          // to follow_button, we need to create an sfs
          fetch('/my/follows.json', {
            method: 'post',
            body: JSON.stringify({artist_id: artist_id}),
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrf_token
            },
            credentials: 'same-origin'
          }).then(function(response) {
            if (response.status == 201) {
              return response.json();
            }
          }).then(function(data) {
            // if the response is fine, set the artistFollowId data
            if (data) {
              follow_button.dataset['artistFollowId'] = data['id'];
              follow_button.innerHTML = 'Following'
            }
          });
        }
        e.preventDefault();
      });
    }
  }
});
