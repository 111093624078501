$(document).on('turbolinks:load', function() {
  $('#discount_code_miniform').each(function() {
    var $this = $(this);
    var discount_code_text = $this.find('input[name=discount_code]');
    var commit_button = $this.find('input[type=submit]');

    // This code will remind the user of the discount code if they
    // enter one without hitting "Apply".  It will pop up when they
    // leave the discount code miniform.
    $this.find('input').on('blur', function(e) {
      if (e.relatedTarget && e.target && e.relatedTarget != commit_button.get(0) && e.relatedTarget != discount_code_text.get(0)) {
        var val = discount_code_text.val().replace(/[^A-Za-z0-9]/, '');
        if (val != '') {
          if (confirm("Do you want to apply your discount code?")) {
            $this.submit();
          }
        }
      }
    });
  });
});
