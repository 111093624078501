import Cookies from 'js-cookie';

$(document).on('turbolinks:load', function() {
  if ($('#renewal-modal').length == 0) {
    if ($('#road-rally-reminder-modal').length > 0) {
      $('#road-rally-reminder-modal').modal('show');
      var isSecure = location.protocol === 'https:';
      Cookies.set('road_rally_reminder_seen', true, { path: '/', expires: 180, secure: isSecure });
    } else if ($('#road-rally-renewal-reminder-modal').length > 0) {
      $('#road-rally-renewal-reminder-modal').modal('show');
    }
  }
});
