document.addEventListener("turbolinks:load", function() {
  let sub_form = document.querySelector('form#submission_songs_form');
  if (sub_form) {
    let unit_price = 5.0;
    try {
      unit_price = parseFloat(document.getElementById('unit_price').value);
    } catch(err) {
      console.log("Cannot find unit price");
    }
    let other_songs_count = 0;
    let other_songs_price = 0;
    try {
      other_songs_count = parseFloat(document.getElementById('other_songs_count').value);
      other_songs_price = parseFloat(document.getElementById('other_songs_price').value);
    } catch(err) {
      console.log("Cannot find other songs stuff");
    }
    // Set up check boxes to automatically show song count and $
    let all_submission_checks = document.querySelectorAll('.songs input[type=checkbox][name="song_ids[]"]');

    let song_count_div = document.querySelector('.submission-checkout .order-total .this-cart .song-count');
    let order_total_div = document.querySelector('.submission-checkout .order-total .this-cart .total');

    let grand_total_div = document.querySelector('.submission-checkout .grand-total .total');

    for (sub_check of all_submission_checks) {
      sub_check.addEventListener('change', function(e) {
        let checked_submission_checks = document.querySelectorAll('.songs input[type=checkbox][name="song_ids[]"]:checked');
  
        const number_of_songs = checked_submission_checks.length;
        const s = (number_of_songs == 1 ? '' : 's');
        song_count_div.innerHTML = `${number_of_songs} song${s}`;
        order_total_div.innerHTML = `$${number_of_songs * unit_price}`;
        if (grand_total_div) {
          grand_total_div.innerHTML = `$${other_songs_price + number_of_songs * unit_price}`;
        }
      });
    }
  }
});
