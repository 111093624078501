/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

require('bootstrap/dist/js/bootstrap')

require("jquery.payment")

require("../card_form.js")
require("../billing_address_form.js")
require("../discount_form.js")

require('../facebox-bootstrap')

//require("jquery-ui/ui/widgets/sortable");
require('webpack-jquery-ui/sortable')

require("table-sortable")
require("table-sortable-local")

require('../clipboard.js')

require('../player.js')

require('../comment-band.js')

require('../song-likes.js')
require('../artist-likes.js')
require('../listing-likes.js')
require('../listing-jump.js')

require('../submission-counter.js')
require('../submission-player.js')

$(document).on('turbolinks:load', function() {
  $('[data-toggle="tooltip"]').tooltip();
});

require('cookies_eu')

require('infinite_scrolling')
require('renewal-modal')
require('road-rally-popup-modal')

require('elisting')
