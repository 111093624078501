document.addEventListener("turbolinks:load", function() {
  let infinite_scrolling = document.getElementById('infinite-scrolling');
  if (infinite_scrolling) {
    add_scroll_listener(infinite_scrolling);
  }
});

function add_scroll_listener(infinite_scrolling) {
  let next_href = infinite_scrolling.querySelector('a.page-link[rel=next]');
  if (next_href) {
    const item_selector = infinite_scrolling.dataset['items'];
    let collection_id = infinite_scrolling.dataset['collection'];
    let collection = undefined;
    if (collection_id) {
      collection = document.getElementById(collection_id);
    }
    function scroll_listener(e) {
      const rect = infinite_scrolling.getBoundingClientRect();
      if (rect.top < window.innerHeight) {
        window.removeEventListener('scroll', scroll_listener);
        // Replace pagination links with loading gif
        while (infinite_scrolling.firstChild) {
          infinite_scrolling.firstChild.remove();
        }
        let loading = document.createElement('img');
        loading.src = '/loading.svg';
        loading.className = 'loading';
        infinite_scrolling.appendChild(loading);
        fetch(next_href, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest'
          },
          credentials: 'same-origin'
        }).then(function(response) {
          if (response.ok) {
            return response.text();
          }
        }).then(function(data) {
          let parser = new DOMParser();
          let parsed_data = parser.parseFromString(data, 'text/html');
          // Clean out infinite-scrolling div - has loading image
          while (infinite_scrolling.firstChild) {
            infinite_scrolling.firstChild.remove();
          }
          // add new items from list
          let new_nodes = parsed_data.querySelectorAll('.' + item_selector);
          for (let new_node of new_nodes) {
            if (collection) {
              collection.insertAdjacentElement('beforeend', new_node);
            } else {
              infinite_scrolling.insertAdjacentElement('beforebegin', new_node);
            }
          }
          // Only add paging links if there's a next page link
          let new_next_href = parsed_data.querySelector('#infinite-scrolling a.page-link[rel=next]');
          if (new_next_href) {
            let new_scrolling_nodes = parsed_data.querySelectorAll("#infinite-scrolling > *");
            for (let new_node of new_scrolling_nodes) {
              infinite_scrolling.insertAdjacentElement('beforeend', new_node);
            }
            add_scroll_listener(infinite_scrolling);
          }
        });
      }
    }
    window.addEventListener('scroll', scroll_listener);
  }
}
