$(document).on('turbolinks:load', function() {
  $('#new_card_info').each(function(i, div) {
    var cardType, cardNumber, cardExp, cardCVC, submit_tag_label;

    $('.cc-number').payment('formatCardNumber');
    $('.cc-exp').payment('formatCardExpiry');
    $('.cc-cvc').payment('formatCardCVC');

    var $form = $(div).parents('form');

    // Show card description if they click "store"
    $('#save_card').on('click', function() {
      if ($(this).prop('checked')) {
        $('#card-description-area').show();
        $('#card_description').prop('required', true);
      } else {
        $('#card-description-area').hide();
        $('#card_description').prop('required', false);
      }
    });

    // for successful input
    function successful_field(field) {
      field.removeClass('is-invalid').addClass('is-valid');
      $('span.sr-only',field.parent('.form-group')).text("(success)");
    }

    // for errors
    function error_field(field) {
      field.removeClass('is-valid').addClass('is-invalid');
      $('span.sr-only',field.parent('.form-group')).text("(error)");
    }

    // nada
    function empty_field(field) {
      field.removeClass('is-valid').removeClass('is-invalid');
      $('span.sr-only',field.parent('.form-group')).text("");
    }

    function cc_number_blur() {
      var cc_number = $('.cc-number').val();
      if (cc_number == '') {
        if (!cardNumber) {
          empty_field($('.cc-number'));
        }
      } else if ($.payment.validateCardNumber(cc_number)) {
        successful_field($('.cc-number'));
        cardType = $.payment.cardType(cc_number);
        cardNumber = cc_number;
        $('.cc-number').val('').prop('placeholder','•••• •••• •••• ' + cc_number.substr(cc_number.length - 4)).prop('required',false);
      } else {
        error_field($('.cc-number'));
        $('.cc-number').prop('placeholder','•••• •••• •••• ••••').prop('required',true);
        cardNumber = undefined;
      }
    }

    $('.cc-number').on('blur', function() {
      cc_number_blur();
    });

    function cc_exp_blur() {
      var cc_exp = $('.cc-exp').val();
      if (cc_exp == '') {
        empty_field($('.cc-exp'));
        cardExp = undefined;
      } else if ($.payment.validateCardExpiry($.payment.cardExpiryVal(cc_exp))) {
        successful_field($('.cc-exp'));
        cardExp = $.payment.cardExpiryVal(cc_exp);
      } else {
        error_field($('.cc-exp'));
        cardExp = undefined;
      }
    }

    $('.cc-exp').on('blur', function() {
      cc_exp_blur();
    });

    function cc_cvc_blur() {
      var cc_cvc = $('.cc-cvc').val();
      if (cc_cvc == '') {
        empty_field($('.cc-cvc'));
        cardCVC = undefined;
      } else if ($.payment.validateCardCVC(cc_cvc, cardType)) {
        successful_field($('.cc-cvc'));
        cardCVC = cc_cvc;
      } else {
        error_field($('.cc-cvc'));
        cardCVC = undefined;
      }
    }

    $('.cc-cvc').on('blur', function() {
      cc_cvc_blur();
    });

    function responseHandler(response) {
      if (response.messages.resultCode === "Error") {
        for (var i = 0; i < response.messages.message.length; i++) {
          console.log(response.messages.message[i].code + ": " + response.messages.message[i].text);
        }

        alert("Your card was declined");

        $('input[type=submit].btn-primary',$form).prop('disabled',false).val(submit_tag_label);
        cardNumber = undefined;
        $('.cc-number').val('');
        $('.cc-number').prop('placeholder','•••• •••• •••• ••••');
        $('.cc-number').focus();
        empty_field($('.cc-number'));
      } else {
        $form.find('input[id$=data_descriptor]').val(response.opaqueData.dataDescriptor);
        $form.find('input[id$=data_value]').val(response.opaqueData.dataValue);
        $form.get(0).submit();
      }
    }

    $form.submit(function(event) {
      var secureData = {}, authData = {}, cardData = {};
      var data_descriptor = $form.find('input[id$=data_descriptor]').val();
      var data_value = $form.find('input[id$=data_value]').val();
      if (data_descriptor && data_value) {
        return true;
      } else {
        if (!cardNumber) { cc_number_blur() }
        if (!cardExp) { cc_exp_blur() }
        if (!cardCVC) { cc_cvc_blur() }
        if (cardNumber && cardExp && cardCVC) {
          submit_tag_label =  $('input[type=submit].btn-primary',$(this)).val();
          $('input[type=submit].btn-primary',$(this)).prop('disabled',true).val('Please Wait...');

          cardData.cardNumber = cardNumber.replace(/[^\d]+/g,'');
          // Month must be two digits
          cardData.month = ("0" + cardExp.month).substr(-2);
          // Yes, I know this breaks in 3000.  I'll probably be dead.
          cardData.year = cardExp.year.toString().replace(/^2\d(\d\d)/,'$1');
          cardData.cardCode = cardCVC;

          secureData.cardData = cardData;

          authData.clientKey = authnet_public_client_key;
          authData.apiLoginID = authnet_api_login_id;
          secureData.authData = authData;

          Accept.dispatchData(secureData, responseHandler);

          return false;
        } else if ($form.hasClass('edit_authnet_card')) {
          // ignore if all blank, warn otherwise
          if (cardNumber || cardExp || cardCVC) {
            alert("All fields are required to update your card number or expiration date.  If you just wish to update the card description, leave all other fields empty.");
            return false;
          }
        } else {
          alert("Please complete your credit card information.");
          return false;
        }
      }
    });
  });
});
