document.addEventListener("turbolinks:load", function() {
  let currently_playing = null;

  let sub_form = document.getElementById('submission_songs_form');

  if (sub_form) {
    let audio_tag = document.getElementById('submission-player');
    if (!audio_tag) { return; }

    sub_form.addEventListener('click', function(e) {
      let element = e.target;
      if (element.tagName == 'A' && element.getAttribute('rel') == 'player') {
        play_or_pause(audio_tag, element);
        e.preventDefault();
        e.stopPropagation();
      }
    });
    sub_form.addEventListener('contextmenu', function(e) {
      let element = e.target;
      if (element.tagName == 'A' && element.getAttribute('rel') == 'player') {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    });
  }

  function play_or_pause(song_player, play_button) {
    if (currently_playing) {
      if (currently_playing == play_button) {
        // This is the currently playing song, so pause
        pause_or_continue_playing(song_player, play_button);
      } else {
        // start playing this
        stop_playing(song_player, currently_playing);
        start_playing(song_player, play_button);
      }
    } else {
      // Nothing playing, so play this
      start_playing(song_player, play_button);
    }
    currently_playing = play_button;
  }

  function stop_playing(song_player, play_button) {
    song_player.pause();
    song_player.currentTime = 0.0;
    song_player.src = undefined;
    play_button.closest('div.form-check').classList.remove('playing', 'paused');
    currently_playing = null;
  }

  function pause_or_continue_playing(song_player, play_button) {
    if (song_player.ended) {
      song_player.currentTime = 0.0;
      song_player.play();
    } else if (song_player.paused) {
      song_player.play();
      play_button.closest('div.form-check').classList.remove('paused');
    } else {
      song_player.pause();
      play_button.closest('div.form-check').classList.add('paused');
    }
  }

  function start_playing(song_player, play_button) {
    song_player.src = play_button.dataset.song + '?at=' + Date.now();
    document.removeEventListener("turbolinks:before-visit", function() {});
    document.addEventListener("turbolinks:before-visit", function() {
      stop_playing(song_player, play_button);
    });
    song_player.play();
    play_button.closest('div.form-check').classList.add('playing');
  }
});
