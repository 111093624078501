$(document).on('turbolinks:load', function() {
  $('table[data-sortable-table-model]').each(function() {
    let prepath = $(this).data('sortable-path-prepend');
    if (!prepath) prepath = '/admin';
    let sort_action = $(this).data('sortable-sort-action');
    if (!sort_action) sort_action = 'sort';
    $(this).sortable({
      dropOnEmpty: false,
      cursor: 'move',
      items: 'tr',
      opacity: 0.4,
      scroll: true,
      update: function() {
        $.ajax({
          type: 'post',
          data: $(this).sortable('serialize'),
          url: prepath + '/' + $(this).data('sortable-table-model') + '/' + sort_action
        });
      }
    });
  });
});
