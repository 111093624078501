document.addEventListener("turbolinks:load", function() {
  const csrf_token = document.querySelector('meta[name=csrf-token]').content;
  let song_likes_holders = document.querySelectorAll('.has-song-like-buttons');

  for (let song_likes_holder of song_likes_holders) {
    song_likes_holder.addEventListener('click', function(e) {
      let element = e.target;
      if (element.classList.contains('like')) {
        const like = element;
        const song_player = element.closest('.artist-song');
        const song_id = song_player.dataset['artistSongId'];
        const sfs_id = like.dataset['sfsId'];
        if (sfs_id) {
          // to unlike, we need to send delete request for this id
          fetch(`/my/subscriber_favorite_songs/${sfs_id}.json`, {
            method: 'delete',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrf_token
            },
            credentials: 'same-origin'
          }).then(function(response) {
            // if the response is fine, we can remove the data
            if (response.status == 204) {
              delete like.dataset['sfsId'];
              return true;
            }
          });
        } else {
          // to like, we need to create an sfs
          fetch('/my/subscriber_favorite_songs.json', {
            method: 'post',
            body: JSON.stringify({artist_song_id: song_id}),
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrf_token
            },
            credentials: 'same-origin'
          }).then(function(response) {
            if (response.status == 201) {
              return response.json();
            }
          }).then(function(data) {
            // if the response is fine, set the sfsId data
            if (data) {
              like.dataset['sfsId'] = data['id'];
            }
          });
        }
        e.preventDefault();
        e.stopPropagation();
      }
    });
  }
});
