document.addEventListener("turbolinks:load", function() {
  let comment_band_holders = document.querySelectorAll('.has-song-comment-bands');

  if (comment_band_holders.length > 0) {
    let my_pic = document.querySelector('.my-pic');
    if (!my_pic) {
      console.log("Cannot find 'my-pic' for comments.");
      return;
    }
    for (let comment_band_holder of comment_band_holders) {
      comment_band_holder.addEventListener('click', function(e) {
        const element = e.target;
        if (element.classList.contains('comment-band')) {
          const comment_band = element;
          const song_player = comment_band.closest('.artist-song');
          const hidden_field = song_player.querySelector('.new-comment form input[name="artist_songs_comment[comment_time]"]');
          // update the time in the comment form
          let offset = e.offsetX / comment_band.offsetWidth;
          hidden_field.value = offset * comment_band.dataset['duration'];
          // add a little picture by copying .my-pic
          let new_pic = comment_band.querySelector('.new-comment-placeholder');
          if (!new_pic) {
            new_pic = my_pic.cloneNode();
            new_pic.classList.add('new-comment-placeholder');
            new_pic.classList.remove('my-pic');
            comment_band.appendChild(new_pic);
          }
          new_pic.style.left = `${100.0 * offset}%`;
          e.preventDefault();
          e.stopPropagation();
        }
      });
    }
  }
});
