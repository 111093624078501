document.addEventListener("turbolinks:load", function() {
  let listing_jump = document.getElementById('listing_jump');
  if (listing_jump) {
    let listing_number = listing_jump.value;
    if (listing_number) {
      let listing_div = document.getElementById('listing-' + listing_number);
      if (listing_div) {
        setTimeout(function() {
          listing_div.scrollIntoView({block: 'start', behavior: 'smooth', inline: 'nearest'});
        }, 1000);
      }
    }
  }
});
