import * as clipboard from "clipboard-polyfill/dist/clipboard-polyfill.promise"

document.addEventListener("turbolinks:load", function() {
  let clipboard_buttons = document.querySelectorAll('.clipboard-copy');

  for (let clippy_button of clipboard_buttons) {
    const target_id = clippy_button.dataset['clipboardTarget'];
    if (target_id) {
      const target = document.querySelector(target_id);
      if (target) {
        clippy_button.addEventListener('click', function(e) {
          let value;
          if (target.nodeName == 'INPUT') {
            value = target.value;
          } else {
            value = target.innerHTML.replace(/^\s+/, '').replace(/\s+$/, '');
          }
          clipboard.writeText(value);
        });
      }
    } else if (clippy_button.nodeName == 'A') {
      clippy_button.addEventListener('click', function(e) {
        clipboard.writeText(clippy_button.href).then(function(x) {
          alert("Link copied to clipboard: " + clippy_button.href);
        });
        e.preventDefault();
      });
    }
  }
});
